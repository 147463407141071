import axios from "./middlewares/axios";

const outdated = async (browser, element) => {
  try {
    const config = await axios.get(`/api/file?name=browsers`);

    for (const data of config.data.browsers) {
      if (
        !browser ||
        (browser.name === data.name &&
          (!data.supported || (data.supported && parseInt(browser.version.split(".").shift(), 10) < data.version)))
      ) {
        let name = "";

        if (browser) {
          switch (browser.name) {
            case "ie":
              name = "Internet Explorer";
              break;
            case "firefox":
              name = "Firefox";
              break;
            case "android":
            case "chrome":
            case "crios":
              name = "Google Chrome";
              break;
            case "safari":
            case "ios":
              name = "Safari";
              break;
            case "opera-mini":
              name = "Opera Mini";
              break;

            default:
              name = "";
          }
        }

        const outdated = document.createElement("div");

        outdated.classList.add("lc-unsupported-warning");
        outdated.onclick = () => outdated.remove();
        outdated.innerHTML = `<div class="lc-unsuported-message">La carte interactive est incompatible avec votre navigateur${
          name ? " " + name + " version " + browser.version : ""
        }. Veuillez mettre à jour ou télécharger un navigateur compatible <a href="http://outdatedbrowser.com/fr" target="_blank">à cette adresse</a></div><div>&#10005;</div></div>`;

        const app = document.querySelector(element);

        app.insertBefore(outdated, app.firstChild);

        break;
      }
    }
  } catch (error) {
    console.log("Can't get browsers list", error);
  }
};

export default outdated;
