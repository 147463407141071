import styled from "styled-components";

export const UIArrow = styled.div`
  width: 20px;
  height: 20px;
  background: url("/assets/images/menu-item-arrow.svg") no-repeat center center;

  transition: all 0.15s ease-in;
  transform: ${(props) => props.isOpen && "rotate(90deg)"};
`;
