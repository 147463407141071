export const actionSetAreas = (areas) => ({
  type: "SET_AREAS",
  areas,
});

export const actionSetStops = (stops) => ({
  type: "SET_STOPS",
  stops,
});

export const actionBuildMarker = (data, options) => ({
  type: "BUILD_MARKER",
  data,
  options,
});

export const actionBuildCustomMarker = (data, options) => ({
  type: "BUILD_CUSTOM_MARKER",
  data,
  options,
});

export const actionOverMarker = (data, target = false) => ({
  type: "OVER_MARKER",
  data,
  target,
});

export const actionOutMarker = (data, target = false) => ({
  type: "OUT_MARKER",
  data,
  target,
});

export const actionOpenMarker = (data) => ({
  type: "OPEN_MARKER",
  data,
});

export const actionOnLineSelected = (line, data) => ({
  type: "ON_LINE_SELECTED",
  line,
  data,
});

export const actionBuildHeavyLines = (zoom, zoomLvl) => ({
  type: "BUILD_HEAVY_LINES",
  zoom,
  zoomLvl,
});

export const actionBuildComplementaryLines = (line = false) => ({
  type: "BUILD_COMPLEMENTARY_LINES",
  line: line,
});

export const actionBuildCustomLines = (lines) => ({
  type: "BUILD_CUSTOM_LINES",
  lines,
});

export const actionBuildTransportPlaces = (places, isLib = false) => ({
  type: "BUILD_TRANSPORT_PLACES",
  places,
  isLib,
});

export const actionBuildMapPlaces = (places) => ({
  type: "BUILD_MAP_PLACES",
  places,
});

export const actionBuildPublicPlaces = (places) => ({
  type: "BUILD_PUBLIC_PLACES",
  places,
});

export const actionBuildAutocomplete = (inputProps, isModal) => ({
  type: "BUILD_AUTOCOMPLETE",
  inputProps,
  isModal,
});

export const actionBuildPlacesByCatInList = (places) => ({
  type: "BUILD_PLACES_BY_CAT_IN_LIST",
  places,
});

export const actionBuildAroundPreferences = () => ({
  type: "BUILD_AROUND_PREFERENCES",
});

export const actionBuildRouteCalcPreferences = () => ({
  type: "BUILD_ROUTE_CALC_PREFERENCES",
});

export const actionBuildTabsPanel = () => ({
  type: "BUILD_TABS_PANEL",
});

export const actionBuildBikePaths = (files, hidePaths = {}) => ({
  type: "BUILD_BIKE_PATHS",
  files,
  hidePaths,
});

export const actionBuildMapBikes = (bikes) => ({
  type: "BUILD_MAP_BIKES",
  bikes,
});
