import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GeoveloElevationChart } from "../components/Chart";
import SectionIcon from "../components/SectionIcon";
import { assetsPath, translate, isSystemUS } from "../services/tools";
import { formatDate } from "../utils/tools";
import {
  Page,
  Header,
  Title,
  TitleText,
  TitleImg,
  Text,
  Texts,
  TextInBold,
  Footer,
  IconWarning,
  // HeaderJourney,
  Summary,
} from "./commun";

const Roadmap = styled.div`
  padding: 10px !important;
  font-size: 1.02em !important;

  .lc-roadmap-summary {
    width: 400px;
    margin-left: 0px !important;
  }

  .lc-section-notes {
    width: 600px;
  }

  .lc-disruptions {
    display: ${(props) => (props.openDisruptions ? "block !important" : "none")};
    width: 400px;

    #lc-disruptions {
      overflow: visible !important;
      height: unset !important;
      visibility: visible !important;
    }

    .lc-disruptions-head {
      display: none !important;
    }
  }
  .lc-roadmap-elevation {
    margin: 0 15px 15px;
    background: white;
    border-radius: 5px;

    canvas {
      margin: 15px;
    }

    .lc-elevation-summary {
      display: flex;
      align-items: center;
      color: #888;
      padding-inline-start: 0px;
      list-style-type: none;
      margin: 0px;

      .lc-elevation-title {
        flex: 1;
        padding: 10px;
        font-weight: 500;
      }

      li.lc-elevation-status {
        display: flex;
        align-items: center;
        padding: 10px;

        height: auto;

        &:last-child {
          border-left: 1px solid rgba(0, 0, 0, 0.08);
        }

        div {
          display: flex;
        }

        .lc-elevation-gain,
        .lc-elevation-loss {
          height: 15px;
        }
      }
    }
  }
`;

const RoadmapTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 0px;
`;

const PrintItineraryRoadmap = (props) => {
  const language = useSelector((state) => state.app.language);
  const now = new Date();
  const date = formatDate(now, isSystemUS(language) ? "m/d/y" : "d/m/y");
  const hours = formatDate(now, isSystemUS(language) ? "h:m a" : "h:m");
  const { datas, page } = props;
  const { journey, component } = datas;

  if (page.openStops) {
    journey.sections
      .filter((s) => s.type === "public_transport")
      .forEach((s) => {
        s.opened = true;
      });
  }

  if (page.openWalkingSections) {
    journey.sections
      .filter((s) => s.type === "street_network" || s.type === "transfer")
      .forEach((s) => {
        s.opened = true;
      });
  }

  const hasDisruptions =
    journey.disruptions && journey.disruptions.filter((disruption) => disruption.severity === "blocking").length > 0;

  return (
    <Page className="lc-print-page">
      <Header>
        <Title className="lc-print-title">
          <TitleImg className="lc-print-title-image" image="route-calculation" />
          <TitleText className="lc-print-title-text">{translate("print-page-title")}</TitleText>
        </Title>
      </Header>
      <Texts>
        <Text>{translate("print-page-date", false, { key: "date", value: date }, { key: "hours", value: hours })}</Text>
        <Text dangerouslySetInnerHTML={{ __html: translate("print-page-check-website") }}></Text>
      </Texts>

      <Summary component={component} />

      <Roadmap
        className="lc-roadmap lc-scroll"
        data-lc-scroll="scroll"
        data-lc-roadmap
        openDisruptions={page.openDisruptions}
      >
        <RoadmapTitle>
          <TextInBold>{translate("print-page-roadmap-details", false)}</TextInBold>
        </RoadmapTitle>
        {/* <HeaderJourney component={component} journey={journey} modes={modes} /> */}
        {journey.sections.map((section, index) => component.renderSection(section, index))}

        {journey.geovelo && (
          <div className="lc-roadmap-elevation lc-scroll" tabIndex="-1" aria-hidden="true">
            <br />
            <RoadmapTitle>
              <TextInBold>{translate("route-calculation-elevation-title", false)} : </TextInBold>
            </RoadmapTitle>
            <ul className="lc-elevation-summary">
              <li className="lc-elevation-status">
                <div>
                  <SectionIcon
                    image="elevation-gain"
                    className="lc-elevation-gain"
                    type="elevation-gain"
                    options={{ height: 12, padding: "0 5px 0 0" }}
                  />
                  {journey.verticalGain}m
                </div>
              </li>
              <li className="lc-elevation-status">
                <div>
                  <SectionIcon
                    image="elevation-loss"
                    className="lc-elevation-loss"
                    type="elevation-loss"
                    options={{ height: 12, padding: "0 5px 0 0" }}
                  />
                  {journey.verticalLoss}m
                </div>
              </li>
            </ul>
            {GeoveloElevationChart(journey, null)}
          </div>
        )}
      </Roadmap>
      {hasDisruptions && (
        <Footer>
          <IconWarning src={assetsPath("/assets/images/disruptions/blocking.svg")} />
          <span dangerouslySetInnerHTML={{ __html: translate("print-page-has-disruptions") }}></span>
        </Footer>
      )}
      <div id="can-print"></div>
    </Page>
  );
};

export default PrintItineraryRoadmap;
